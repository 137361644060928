import validate from "/builds/leviia/console/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/builds/leviia/console/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "can-access-object-storage-buckets": () => import("/builds/leviia/console/middleware/can-access-object-storage-buckets.ts"),
  "can-access-object-storage-identifiers": () => import("/builds/leviia/console/middleware/can-access-object-storage-identifiers.ts"),
  "can-access-object-storage": () => import("/builds/leviia/console/middleware/can-access-object-storage.ts"),
  "can-access-params-teams": () => import("/builds/leviia/console/middleware/can-access-params-teams.ts"),
  "can-access-partners": () => import("/builds/leviia/console/middleware/can-access-partners.ts"),
  "has-any": () => import("/builds/leviia/console/middleware/has-any.ts"),
  "has-drive-or-canceled": () => import("/builds/leviia/console/middleware/has-drive-or-canceled.ts"),
  "has-drive-pro-or-canceled": () => import("/builds/leviia/console/middleware/has-drive-pro-or-canceled.ts"),
  "has-drive-pro": () => import("/builds/leviia/console/middleware/has-drive-pro.ts"),
  "has-drive": () => import("/builds/leviia/console/middleware/has-drive.ts"),
  "has-not-drive-pro": () => import("/builds/leviia/console/middleware/has-not-drive-pro.ts"),
  "has-not-drive": () => import("/builds/leviia/console/middleware/has-not-drive.ts"),
  "has-not-partner": () => import("/builds/leviia/console/middleware/has-not-partner.ts"),
  "has-not-s3": () => import("/builds/leviia/console/middleware/has-not-s3.ts"),
  "has-partner-or-canceled": () => import("/builds/leviia/console/middleware/has-partner-or-canceled.ts"),
  "has-partner": () => import("/builds/leviia/console/middleware/has-partner.ts"),
  "has-s3-or-canceled": () => import("/builds/leviia/console/middleware/has-s3-or-canceled.ts"),
  "has-s3": () => import("/builds/leviia/console/middleware/has-s3.ts"),
  "not-reseller": () => import("/builds/leviia/console/middleware/not-reseller.ts"),
  "reseller-active": () => import("/builds/leviia/console/middleware/reseller-active.ts"),
  "s3-editable": () => import("/builds/leviia/console/middleware/s3-editable.ts"),
  "s3-has-bucket": () => import("/builds/leviia/console/middleware/s3-has-bucket.ts"),
  "s3-has-identifier": () => import("/builds/leviia/console/middleware/s3-has-identifier.ts"),
  "s3-has-quota": () => import("/builds/leviia/console/middleware/s3-has-quota.ts"),
  "s3-not-trial": () => import("/builds/leviia/console/middleware/s3-not-trial.ts"),
  "s3-trial-not-max": () => import("/builds/leviia/console/middleware/s3-trial-not-max.ts")
}